<template>
    <div class="order-detail-info">
        <el-table border :data="orderDetailInfoOld" size="mini">
            <el-table-column type="index" label="序号" width="50"></el-table-column>
            <el-table-column prop="externalOrderNo" label="外部子单号"></el-table-column>
            <el-table-column prop="externalMainOrderNo" label="外部主单号"></el-table-column>
            <el-table-column prop="sourceOrderMainNo" label="订单来源主单号"></el-table-column>
            <el-table-column property="status" label="订单状态" :formatter="formatOrderStatus"></el-table-column>
            <el-table-column prop="deliveryTime" label="配送时间">
                <template slot-scope="scope">
                    <span>{{formatDate(scope.row.deliveryTime)}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="pickupTime" label="提货时间">
                <template slot-scope="scope">
                    <span>{{formatDate(scope.row.pickupTime)}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="productIsGift" label="是否赠品" :formatter="formatIsGift"></el-table-column>
            <el-table-column prop="productName" label="产品名称"></el-table-column>
            <el-table-column prop="productType" label="产品类型" :formatter="formatProductType"></el-table-column>
            <el-table-column prop="productNum" label="产品数量"></el-table-column>
            <el-table-column prop="payBalancePerAmount" label="余额支付"></el-table-column>
            <el-table-column prop="payWechatPerAmount" label="微信支付"></el-table-column>
            <el-table-column prop="payAmount" label="实付金额"></el-table-column>
            <el-table-column prop="cashAmount" label="最终支付金额(不含余额部分)"></el-table-column>
            <el-table-column prop="isPromotion" label="是否参与促销" :formatter="formatIsPromotion"></el-table-column>
            <el-table-column prop="promotionId" label="促销id"></el-table-column>
            <el-table-column prop="promotionName" label="促销名称"></el-table-column>
            <el-table-column prop="suitId" label="套装Id"></el-table-column>
            <el-table-column prop="suitName" label="套装名称"></el-table-column>
        </el-table>
    </div>
</template>
<script>
export default{
    data(){
        return {
            orderNo:this.$route.params.orderNo,
        }
    },

    props:['orderDetailInfoOld'],

    mounted(){

    },

    methods:{
        returnHistory(){
            window.history.go(-1)
        },

        formatOrderStatus: function (row, column) {
            var status = row.status;
            if (status == 0) {
                return "已取消";
            }
            if(status == 5){
                return "待支付";
            }
            if(status == 10){
                return "已支付";
            }
            if (status == 15) {
                return "部分配送";
            }
            if(status == 20){
                return "全部配送";
            }
            if(status == 25){
                return "部分发货";
            }
            if(status == 30){
                return "已发货";
            }
            if(status == 35){
                return "已送达";
            }
            if(status == 45){
                return "交易关闭";
            }
        },
        formatProductType: function (row, column) {
            if(row.productType == 1){
                return  "spu";
            }
            if(row.productType == 2){
                return  "虚拟卡";
            }
            if(row.productType == 3){
                return  "物品";
            }

        },
        formatIsGift: function (row, column){
            if(row.productIsGift == 1){
                return  "是";
            }
            if(row.productIsGift == 2){
                return  "否";
            }
        },
        formatDate(datetime) {
            if(datetime == null){
                return;
            }
            var date = new Date(datetime);
            var year = date.getFullYear(),
                month = ("0" + (date.getMonth() + 1)).slice(-2),
                sdate = ("0" + date.getDate()).slice(-2),
                hour = ("0" + date.getHours()).slice(-2),
                minute = ("0" + date.getMinutes()).slice(-2),
                second = ("0" + date.getSeconds()).slice(-2);
            // 拼接
            var result = year + "-"+ month +"-"+ sdate +" "+ hour +":"+ minute +":" + second;
            // 返回
            return result;
        },
        formatIsPromotion: function (row, column){
            if(row.isPromotion == 1){
                return  "参与";
            }
            if(row.isPromotion == 2){
                return  "未参与";
            }
        }
    }
}
</script>

<style lang="scss" rel="stylesheet/scss">

.order-detail-info{
    margin-top: 20px;
    .red .el-form-item__label{
        color: red;
    }
    .el-input{
        width: 100%;
        height: 32px;
    }
    .el-tag{
        width: 100%;
        height: 32px;
    }
    .el-avatar{
        display: block;
        margin: 0 auto;
    }
}
</style>
