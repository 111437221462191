<template>
    <div>
        <defaultSec :title="'< 订单详情'" :returnState="true">
            <el-tabs v-model="activeName" type="border-card">
                <el-tab-pane label="订单详细信息" name="0">
                    <orderDetailInfoOld :orderDetailInfoOld="orderDetailInfoOld"></orderDetailInfoOld>
                </el-tab-pane>
                <el-tab-pane label="订单产品信息" name="1">
                    <orderProductInfo :orderProductInfo="orderProductInfo" :pickedup="pickedup"></orderProductInfo>
                </el-tab-pane>
            </el-tabs>
        </defaultSec>
    </div>
</template>

<script>
import defaultSec from '@c/defaultSection'
import orderDetailInfoOld from '@/components/orderSystem/orderDetailInfoOld'
import orderProductInfo from '@/components/orderSystem/orderProductInfo'
export default {
    data() {
        return {
            externalOrderNo:this.$route.params.externalOrderNo,
            orderDetailInfoOld:[],
            orderProductInfo:[],
            activeName:'0',
            pickedup: false
        }
    },
    components:{
        defaultSec,orderDetailInfoOld,orderProductInfo
    },
    mounted(){
        this.initList()
    },
    methods: {
        initList() {
            this.$apiGet('lechun-csms/orderApi/getOrderDetailInfo',{externalOrderNo:this.externalOrderNo}).then(res => {
                console.log(res);
                this.orderDetailInfoOld = res;
            }),
                this.$apiGet('lechun-csms/orderApi/getOrderProductInfo',{externalOrderNo:this.externalOrderNo}).then(res => {
                    console.log(res);
                    this.orderProductInfo = res;
                    if(this.orderProductInfo != null && this.orderProductInfo.length > 0 && this.orderProductInfo[0].pickupTime < (new Date()).valueOf()){
                        this.pickedup = true;
                    }
                })
        }
    }
}
</script>


<style scoped>

</style>
